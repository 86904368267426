/**
 * copy from web/views/assets/css/global.css
 */

.popapp-post-con {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  cursor: pointer;
  color: #58595b;
  transition: opacity 0.4s, transform 0.4s;
}

.popapp-post-con.hide {
  opacity: 0;
  transform: translateY(-10px);
}

.popapp-post-con .title:empty,
.popapp-post-con .des:empty {
  display: none;
}

.popapp-post-con .img {
  width: 80px;
  height: 80px;
  background: rgb(237, 238, 240);
  margin-left: 15px;
}

.popapp-post-con .info {
  flex: 1;
}

.popapp-post-con .title {
  line-height: 2em;
  font-weight: 600;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.popapp-post-con .des {
  line-height: 1.5em;
  font-size: 0.9em;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.popapp-post-con .title:empty + .des {
  -webkit-line-clamp: 4;
}

.popapp-post-con.pgc .title {
  line-height: 1.5em;
  -webkit-line-clamp: 3;
}

.popapp-post-con .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popapp-post-con .detail {
  display: flex;
  width: 100%;
  /*margin: 0 -15px;*/
  padding-top: 15px;
  font-size: 0.8em;
}

.popapp-post-con .detail .author-con {
  flex: 1;
  min-width: 0;
}

.popapp-post-con .detail .author-con .poster-img {
  position: relative;
  float: left;
  width: 18px;
  height: 18px;
  margin: 0;
  vertical-align: middle;
  border-radius: 100px;
  overflow: hidden;
  background: #ccc;
}

.sec-con > .info .author-con .poster-img img {
  display: block;
  max-width: 100%;
  height: auto;
}

.popapp-post-con .author-con .poster-name {
  display: inline-block;
  width: calc(100% - 33px);
  padding-left: 5px;
  overflow: hidden;
  height: 18px;
  line-height: 18px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.popapp-post-con .like-con .like-num {
  float: right;
  margin-left: 3px;
  line-height: 18px;
  color: #666;
}

.popapp-post-con .like-con .like-icon {
  float: right;
  width: 18px;
  height: 18px;
  margin-top: 0px;
  background-image: url(https://static.popdaily.com.tw/img/globalicon/like01.png?0425);
  background-size: cover;
  background-position: center;
  margin-right: 1px;
}

.popapp-post-con:empty {
  display: none;
}
